.containers {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.middle {
  display: flex;
  justify-content: center;
}
